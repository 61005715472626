import { useEffect } from "react"
import { useLocation } from "react-router-dom"

const useScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }, [pathname])
}

export default useScrollToTop
