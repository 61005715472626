import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import styles from "./Services.module.css"
import { useTranslation } from "react-i18next"

export const ServiceCard = ({ image, title, link }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })

  const { t } = useTranslation()
  return (
    <motion.div
      className={styles.serviceCard}
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.5 }}
    >
      <img src={image} alt={title} className={styles.serviceImage} />
      <div className={styles.serviceInfo}>
        <h3>{title}</h3>
        <a href={link} className={styles.viewMoreButton}>
          {t("services.services.button")}
        </a>
      </div>
    </motion.div>
  )
}
