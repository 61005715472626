import React from "react"
import Style from "./NotFound.module.css" 

export default function NotFound() {
  return (
    <div className={Style.container}>
      <div className={Style.content}>
        <h1 className={Style.heading}>404</h1>
        <p className={Style.message}>
          Oops! The page you're looking for doesn't exist.
        </p>
        <button
          className={Style.button}
          onClick={() => (window.location.href = "/")}
        >
          Go Back Home
        </button>
      </div>
    </div>
  )
}
