import React from "react"
import { Phone, Mail, Send } from "lucide-react"
import useScrollToTop from "../../utils/hooks"
import { useTranslation } from "react-i18next"

const ContactUs = () => {
  useScrollToTop()
  const { t } = useTranslation()
  return (
    <>
      <div className="bg-gradient-to-tl from-blue-900 to-blue-950 absolute top-0 w-full h-24"></div>
      <section className=" min-h-screen py-14 md:py-44 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto mt-20 md:mt-5">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
            {/* Contact Info Boxes */}
            <div className="md:col-span-3 space-y-4">
              <ContactInfoBox
                icon={<Phone className="w-8 h-8" />}
                title={t("footer.callus.title")}
                content="+20 106 090 8907"
              />
              <ContactInfoBox
                icon={<Mail className="w-8 h-8" />}
                title={t("footer.email.title")}
                content="ysglassco@gmail.com"
              />
              {/* Social Media Links */}
              <div className="md:col-span-1 flex md:flex-col justify-center items-center space-x-4 md:space-x-0 md:space-y-4 ">
                <div className="flex gap-4 p-3 rounded-xl">
                  <SocialLink
                    href="https://www.facebook.com/YassinGlass1"
                    icon="fa-facebook"
                  />

                  <SocialLink
                    href="https://www.instagram.com/yassinglass101/"
                    icon="fa-instagram"
                  />

                  <SocialLink
                    href="https://twitter.com/YassinGlass"
                    icon="fa-x-twitter"
                  />

                  <SocialLink
                    href="https://www.linkedin.com/in/yassin-glass-311b31234/"
                    icon="fa-linkedin-in"
                  />
                </div>
              </div>
            </div>

            {/* Contact Form */}
            <div className="md:col-span-8">
              <form
                className="bg-white shadow-xl rounded-lg p-8"
                action="https://formsubmit.co/ysglassco@gmail.com"
                method="POST"
              >
                <div className="space-y-6">
                  <Input name="name" placeholder="Name" type="text" required />
                  <Input
                    name="email"
                    placeholder={t("contact.email")}
                    type="email"
                    required
                  />
                  <Input
                    name="phone"
                    placeholder={t("contact.phone")}
                    type="tel"
                    required
                  />
                  <textarea
                    name="textarea"
                    placeholder={t("contact.message")}
                    className="w-full px-4 py-3 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    rows="4"
                    required
                  />
                  <div className="text-center">
                    <button
                      type="submit"
                      className="inline-flex gap-3 items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-800 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      {t("contact.button")}
                      <Send className=" -mr-1 w-5 h-5" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

const ContactInfoBox = ({ icon, title, content }) => (
  <div className="bg-white rounded-lg shadow-xl p-6 transform transition duration-500 hover:scale-105">
    <div className="flex items-center justify-center mb-4 text-blue-600">
      {icon}
    </div>
    <h3 className="text-lg font-semibold text-gray-800 mb-2">{title}</h3>
    <p dir="ltr" className="text-gray-600">
      {content}
    </p>
  </div>
)

const Input = ({ name, placeholder, type, required }) => (
  <input
    name={name}
    placeholder={placeholder}
    type={type}
    required={required}
    className="w-full px-4 py-3 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
  />
)

const SocialLink = ({ href, icon }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="text-blue-700 hover:text-blue-200 transition-colors duration-300"
  >
    <i className={`fa-brands ${icon} text-2xl`}></i>
  </a>
)

export default ContactUs
