import React, { useEffect } from "react"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { Parallax } from "react-parallax"
import styles from "./Home.module.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import {
  glass1,
  glass2,
  glass3,
  glass4,
  glass5,
  glass6,
  glass7,
  glass8,
  glass9,
  glass10,
  glass11,
  glass12,
  glass13,
  glass14,
  glass15,
  glass16,
  glass17,
  glass18,
  glass19,
  glass20,
  mirror1,
  mirror2,
  mirror3,
  mirror4,
  mirror5,
  mirror6,
  mirror7,
  mirror8,
  mirror9,
  mirror10,
  mirror11,
  mirror12,
  mirror13,
  mirror14,
  mirror15,
  mirror16,
  mirror17,
  mirror18,
  mirror19,
  mirror20,
  shower1,
  shower2,
  shower3,
  shower4,
  shower5,
  shower6,
  shower7,
  shower8,
  shower9,
  shower10,
  shower11,
  shower12,
  shower13,
  shower14,
  shower15,
  shower16,
  shower17,
  shower18,
  shower19,
  shower20,
  facades1,
  facades2,
  facades3,
  facades4,
  facades5,
  facades6,
  facades7,
  facades8,
  facades9,
  facades10,
  facades11,
  facades12,
  facades13,
  facades14,
  facades15,
  facades16,
  facades17,
  facades18,
  facades19,
  facades20,
  facades21,
  facades22,
  facades23,
  serv1,
  serv2,
  serv3,
  serv4,
  artHouse,
  golds,
  hyde,
  mountain,
  pepsi,
} from "../../utils/imageindex"
import { Link } from "react-router-dom"
import "react-image-gallery/styles/css/image-gallery.css"
import { ServiceCard } from "../ServiceCard/ServiceCard"
import Pslider from "../Pslider/Pslider"
import VideoControl from "../VideoControl/VideoControl"
import bgHomeLaptop from "../../Assets/compressedImages/enhanced/Facades/912.webp"
import { useTranslation } from "react-i18next"

export default function Home() {
  const { t, i18n } = useTranslation()
  const facades = [
    facades1,
    facades2,
    facades3,
    facades4,
    facades5,
    facades6,
    facades7,
    facades8,
    facades9,
    facades10,
    facades11,
    facades12,
    facades13,
    facades14,
    facades15,
    facades16,
    facades17,
    facades18,
    facades19,
    facades20,
    facades21,
    facades22,
    facades23,
  ]
  const mirros = [
    mirror1,
    mirror2,
    mirror3,
    mirror4,
    mirror5,
    mirror6,
    mirror7,
    mirror8,
    mirror9,
    mirror10,
    mirror11,
    mirror12,
    mirror13,
    mirror14,
    mirror15,
    mirror16,
    mirror17,
    mirror18,
    mirror19,
    mirror20,
  ]
  const shower = [
    shower1,
    shower2,
    shower3,
    shower4,
    shower5,
    shower6,
    shower7,
    shower8,
    shower9,
    shower10,
    shower11,
    shower12,
    shower13,
    shower14,
    shower15,
    shower16,
    shower17,
    shower18,
    shower19,
    shower20,
  ]
  const handrill = [
    glass1,
    glass2,
    glass3,
    glass4,
    glass5,
    glass6,
    glass7,
    glass9,
    glass10,
    glass11,
    glass12,
    glass13,
    glass14,
    glass15,
    glass16,
    glass17,
    glass18,
    glass19,
    glass20,
  ]

  const LogoItem = ({ src, alt }) => (
    <motion.div
      className={styles.logoItem}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <img src={src} alt={alt} />
    </motion.div>
  )

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })
  useEffect(() => {
    if (i18n.language === "ar") {
      document.documentElement.dir = "rtl"
      document.documentElement.lang = "ar"
    } else {
      document.documentElement.dir = "ltr"
      document.documentElement.lang = "en"
    }
  }, [])

  return (
    <div className="overflow-hidden">
      {/* Home Section */}
      <section id="home">
        <Parallax
          blur={{ min: -15, max: 15 }}
          bgImage={bgHomeLaptop}
          className={`${window.innerWidth < 768 ? "" : "h-screen"} w-full`}
          bgImageAlt="Glass background"
          strength={600}
        >
          <section id="home" className={styles.home}>
            <div className={styles.overlay}>
              <motion.div
                ref={ref}
                initial={{ opacity: 0, y: 50 }}
                animate={inView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.8 }}
                className={styles.content}
              >
                <h1 className={styles.title}>
                  {t("home.title")}
                  <br />
                  {t("home.subtitle")}
                </h1>
                <p className={styles.description}>{t("home.description")}</p>
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Link to="/contact-us" className={styles.ctaButton}>
                    {t("home.button")}
                  </Link>
                </motion.div>
              </motion.div>
            </div>
          </section>
        </Parallax>
      </section>
      {/* Services Section */}
      <section id="services" className={styles.services}>
        <div className={styles.servicesOverlay}>
          <div className={styles.servicesContent}>
            <h2>{t("services.title")}</h2>
            <p>{t("services.description")}</p>
          </div>
        </div>
        <div className={styles.serviceGrid}>
          <ServiceCard
            image={serv1}
            title={`${t("services.services.facade")}`}
            link="#facades"
          />
          <ServiceCard
            image={serv2}
            title={`${t("services.services.mirrors")}`}
            link="#mirror"
          />
          <ServiceCard
            image={serv3}
            title={`${t("services.services.showers")}`}
            link="#shower"
          />
          <ServiceCard
            image={serv4}
            title={`${t("services.services.handrails")}`}
            link="#glass"
          />
        </div>
      </section>
      {/* logos Section */}
      <section id="logos_section" className={styles.logos}>
        <div className={styles.container_logo}>
          <motion.h2
            className={styles.title_logo}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            {t("clients.title")}
          </motion.h2>
          <div className={styles.logoGrid}>
            <LogoItem src={artHouse} alt="Art House" />
            <LogoItem src={pepsi} alt="Pepsi" />
            <LogoItem src={hyde} alt="Hyde Park" />
            <LogoItem src={golds} alt="Gold's Gym" />
            <LogoItem src={mountain} alt="Mountain View" />
          </div>
        </div>
      </section>
      {/* Video Section */}
      <section id="about" className={styles.video}>
        <div className={styles.container_video}>
          <VideoControl />
        </div>
      </section>
      {/* Facades Section */}
      <section id="facades">
        <Pslider
          title={`${t("sliders.facades.title")}`}
          heading={`${t("sliders.facades.subtitle")}`}
          images={facades}
          container={`bg-gray-800 text-white`}
        />
      </section>
      {/* Shower Cabins */}
      <section id="shower">
        <Pslider
          title={`${t("sliders.shower.title")}`}
          heading={`${t("sliders.shower.subtitle")}`}
          images={shower}
        />
      </section>
      {/* Mirrors Sections */}
      <section id="mirror">
        <Pslider
          title={`${t("sliders.mirrors.title")}`}
          heading={`${t("sliders.mirrors.subtitle")}`}
          images={mirros}
          container={`bg-gray-800 text-white`}
        />
      </section>
      {/* Glass handrail Sections */}
      <section id="glass">
        <Pslider
          title={`${t("sliders.handrail.title")}`}
          heading={`${t("sliders.handrail.subtitle")}`}
          images={handrill}
        />
      </section>
    </div>
  )
}
