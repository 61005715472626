import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import resources from "./translation/translation.json"

i18n.use(initReactI18next).init({
  resources: {
    ar: {
      translation: resources.ar,
    },
    en: {
      translation: resources.en,
    },
  },
  lng: localStorage.getItem("siteLanguage") || "en",
})

export default i18n
