import React, { useState, useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { Link, useLocation } from "react-router-dom"
import { Menu, X, Globe } from "lucide-react"
import logo from "../../Assets/compressedImages/logo.webp"
import { useTranslation } from "react-i18next"

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const location = useLocation()

  const { t, i18n } = useTranslation()

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20)
    }
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  useEffect(() => {
    setIsMenuOpen(false)
    if (location.hash) {
      const sectionId = location.hash.replace("#", "")
      const section = document.getElementById(sectionId)
      if (section) {
        setTimeout(() => {
          section.scrollIntoView({ behavior: "smooth", block: "start" })
        }, 200)
      }
    }
  }, [location])

  const navItems = [
    { name: t("nav.home"), path: "/#home" },
    { name: t("nav.about"), path: "/#about" },
    { name: t("nav.FAQ"), path: "/FAQs" },
    { name: t("nav.contact"), path: "/contact-us" },
  ]

  const isActive = (path) => {
    if (path.startsWith("/#")) {
      return location.pathname === "/" && location.hash === path.substring(1)
    }
    return location.pathname === path
  }

  const toggleLanguage = () => {
    const newLang = i18n.language === "en" ? "ar" : "en"
    i18n.changeLanguage(newLang)
    document.documentElement.dir = newLang === "ar" ? "rtl" : "ltr"
    document.documentElement.lang = newLang
    localStorage.setItem("siteLanguage", newLang)
  }

  return (
    <motion.header
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ type: "spring", stiffness: 300, damping: 30 }}
      className={`fixed w-full z-50 transition-all duration-300 ${
        isScrolled ? "bg-white shadow-lg" : "bg-transparent"
      }`}
    >
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        {/* Logo Section */}
        <Link to="/" className="flex items-center space-x-2 no-underline">
          <img
            src={logo}
            alt="YassinGlass"
            className="w-12 h-12 object-contain"
          />
          <span
            className={`text-2xl font-bold tracking-wide no-underline ${
              isScrolled ? "text-gray-900" : "text-white"
            }`}
          >
            {t("h1")}
          </span>
        </Link>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex items-center">
          {navItems.map((item) => (
            <Link
              key={item.name}
              to={item.path}
              className={`font-bold mx-3 transition-colors duration-200 text-xl no-underline relative ${
                isScrolled
                  ? "text-blue-900 hover:text-blue-500"
                  : "text-white hover:text-gray-300"
              } ${
                isActive(item.path)
                  ? 'after:content-[""] after:absolute after:bottom-[-4px] after:left-0 after:w-full after:h-[2px] after:bg-current'
                  : ""
              }`}
            >
              {item.name}
            </Link>
          ))}
          <button
            onClick={toggleLanguage}
            className={`flex items-center space-x-1 font-bold transition-colors duration-200 ${
              isScrolled
                ? "text-blue-900 hover:text-blue-500"
                : "text-white hover:text-gray-300"
            }`}
          >
            <Globe className="w-5 h-5" />
            <span>{i18n.language === "en" ? "عربي" : "English"}</span>
          </button>
        </nav>

        {/* Mobile Menu Button */}
        <div className="md:hidden flex items-center space-x-4">
          <button
            onClick={toggleLanguage}
            className={`flex items-center space-x-1 font-bold transition-colors duration-200 ${
              isScrolled
                ? "text-blue-900 hover:text-blue-500"
                : "text-white hover:text-gray-300"
            }`}
          >
            <Globe className="w-5 h-5" />
            <span>{i18n.language === "en" ? "عربي" : "English"}</span>
          </button>
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="focus:outline-none"
            aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          >
            {isMenuOpen ? (
              <X
                className={`${
                  isScrolled ? "text-gray-900" : "text-white"
                } w-6 h-6`}
              />
            ) : (
              <Menu
                className={`${
                  isScrolled ? "text-gray-900" : "text-white"
                } w-6 h-6`}
              />
            )}
          </button>
        </div>
      </div>

      {/* Mobile Navigation */}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="md:hidden fixed top-[72px] left-0 w-full bg-white shadow-lg overflow-hidden"
          >
            <div className="container mx-auto px-4 py-2">
              {navItems.map((item) => (
                <Link
                  key={item.name}
                  to={item.path}
                  className={`block rounded my-1 py-3 p-2 text-lg text-gray-800 hover:bg-gray-100 transition-colors no-underline ${
                    isActive(item.path) ? "bg-gray-100 font-bold ps-3" : ""
                  }`}
                  onClick={() => setIsMenuOpen(false)}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.header>
  )
}

export default Header
